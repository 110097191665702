import React from "react"
import LayoutA2 from "../../components/layout-a2"
import EnrollStep from "../../components/enroll-step"

export default class A2EnrollStep extends React.Component {
  render() {
    const color='#1a55a3'
    const src='/demos/themeA2/enroll'
    return(
      <LayoutA2 >
          <EnrollStep color={color} src={src} />
      </LayoutA2>
    )
  }
}