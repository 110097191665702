// import provinces from 'china-division/dist/provinces.json';
// import cities from 'china-division/dist/cities.json';
// import areas from 'china-division/dist/areas.json';
import countries from 'country-state-city/dist/lib/country.json';
import  states from 'country-state-city/dist/lib/state.json';
import cities from 'country-state-city/dist/lib/city.json'

cities.forEach((city) => {
  const matchState = states.filter(state => state.id === city.state_id)[0];
  if (matchState) {
    matchState.children = matchState.children || [];
    matchState.children.push({
      label: city.name,
      value: city.id,
    });
  }
});

states.forEach((state) => {
  const matchCountry = countries.filter(country => country.id === state.country_id)[0];
  if (matchCountry) {
    matchCountry.children = matchCountry.children || [];
    matchCountry.children.push({
      label: state.name,
      value: state.id,
      children: state.children,
    });
  }
});

const options = countries.map(country => ({
  label: country.name,
  value: country.id,
  children: country.children,
}));

export default options;